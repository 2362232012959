import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {PasswordResetSteps} from "../../models/forgot-password-steps.enum";
import {LocalizedRouterService} from "../../services/localized-router.service";
import {LocalizedErrorTranslateService} from "../../services/localized-error-translate.service";
import {AuthService} from "../../services/auth.service";

@Component({
    selector: 'app-password-forgot-confirm',
    templateUrl: './confirm-forgot-password.component.html',
    styleUrl: './confirm-forgot-password.component.css'
})
export class ConfirmForgotPasswordComponent {
    @Output() onStepChange = new EventEmitter<{ step: PasswordResetSteps, emailAddress: any }>();
    @Input() emailAddress = '';

    passwordPattern: string = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\^$*.[\\]{}()?\"!@#%&/\\\\,<>'':;|_~`+=-]).{8,}$";
    inProgress:boolean = false;
    confirmationCode: any;
    newPassword: any;
    confirmPassword: any;

    constructor(private routerService: LocalizedRouterService,
                private messageService: MessageService,
                private translationService: TranslateService,
                private localizedErrorTranslateService: LocalizedErrorTranslateService,
                private authService: AuthService) {
    }

    private confirmForgotPasswordCompleted = (): void => {
        this.messageService.add({
            severity: 'success',
            detail: this.translationService.instant('passwordSuccessfullyChanged')
        });
        this.routerService.navigate(['/login']);
    }

    private handleError = (error: {}): void => {
        this.localizedErrorTranslateService.handleError(error)
    }

    async onConfirmResetCode() {
        this.inProgress = true;
        this.authService.confirmForgotPassword({
            username: this.emailAddress,
            password: this.newPassword,
            code: this.confirmationCode,
        }).subscribe({
            complete: (): void => this.confirmForgotPasswordCompleted(),
            error: (error): void => this.handleError(error),
        })
        this.inProgress = false;
    }
}
