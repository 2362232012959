import { Component, OnInit } from '@angular/core';
import { LogoService } from './services/logo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  logoData: { logoSrc: string, logoLink: string, footerLogoSrc: string } | null = null;

  constructor(private logoService: LogoService) {}

  ngOnInit() {
    this.logoService.loadLogoData().then(data => {
      this.logoData = data;
    });
  }
}
