import {Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {LocaleService} from "../services/locale.service";
import {Subscription} from "rxjs";

@Directive({
    selector: '[appLocalizedRouterLink]'
})
export class LocalizedRouterLinkDirective implements OnInit, OnDestroy {

    @Input() appLocalizedRouterLink: string = '';

    localeSubscription!: Subscription;

    constructor(
        private localeService: LocaleService,
        private element: ElementRef,
        private renderer: Renderer2) {
    }

    ngOnDestroy(): void {
        if (this.localeSubscription) {
            this.localeSubscription.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.localeSubscription = this.localeService.locale$.subscribe(locale => {
            const newPath = `/${locale}/${this.appLocalizedRouterLink}`;
            this.renderer.setAttribute(this.element.nativeElement, 'href', newPath);
        });
    }

    extractLanguageFromPath(url: string): string | null {
        const pathMatch = url.match(/\/[a-z]{2}-([a-z]{2})\//i);
        return pathMatch ? pathMatch[1] : null; // 'en' als Fallback-Sprache
    }
}