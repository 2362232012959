import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocaleService } from './locale.service';

@Injectable({
    providedIn: 'root'
})
export class LocalizedRouterService {
    constructor(private router: Router, private localeService: LocaleService) {}

    navigate(commands: any[], extras?: any) {
        const localizedCommands = ['/' + this.localeService.locale + commands[0]];
        this.router.navigate(localizedCommands, extras);
    }

    navigateByUrl(newUrl: string, extras: any) {
        this.router.navigateByUrl(newUrl, extras);
    }
}
