import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LocalizedErrorTranslateService} from "../../services/localized-error-translate.service";
import {AuthService} from "../../services/auth.service";

@Component({
    selector: 'app-setup-totp',
    templateUrl: './setup-totp.component.html',
    styleUrl: './setup-totp.component.css'
})
export class SetupTotpComponent {

    @Output() onStepChange = new EventEmitter<any>();
    @Input() foreChangePasswordStep: string = 'false';

    inProgress = false;

    @Input() authQrCode: string = '';
    totpCode: string = '';

    constructor(private localizedErrorTranslateService: LocalizedErrorTranslateService,
                private authService: AuthService) {
    }

    async onConfirmSignIn() {
        this.inProgress = true;

        try {
            const {isSignedIn, nextStep} = await this.authService.confirmSignIn({
                challengeResponse: this.totpCode,
                options: {
                    authFlowType: 'USER_SRP_AUTH',
                    clientMetadata: {
                        foreChangePasswordStep: this.foreChangePasswordStep,
                    }
                }
            });

            this.onStepChange.emit({isSignedIn: isSignedIn, nextStep: nextStep});
        } catch (error) {
            this.localizedErrorTranslateService.handleError(error);
        }
        this.inProgress = false;
    }
}
