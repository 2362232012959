import {Injectable} from "@angular/core";
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {InterceptorService} from "../interceptors/interceptor.service";

@Injectable({
    providedIn: 'root'
})
export class LocalizedErrorTranslateService {

    constructor(private messageService: MessageService,
                private translate: TranslateService,
                private interceptorService: InterceptorService) {
    }

    handleError(error: any) {
        if(this.interceptorService.redirectInProgress) {
            // interceptor is redirecting, do not handle eny error ...
            return;
        }
        let errorName = 'ui.error.message';

        if ('name' in error) {
            errorName = error.name;
        }

        this.messageService.add({
            severity: 'error',
            summary: '',
            detail: '' + this.getTranslation(errorName, 'ui.error.message')
        });
    }

    private getTranslation(key: string, fallback: string): string {
        const translation = this.translate.instant(key);
        return translation === key ? this.translate.instant(fallback) : translation;
    }

}