import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MessageService} from "primeng/api";
import {LocalizedErrorTranslateService} from "../../services/localized-error-translate.service";
import {AuthService} from "../../services/auth.service";

@Component({
    selector: 'app-select-mfa',
    templateUrl: './select-mfa.component.html',
    styleUrl: './select-mfa.component.css'
})
export class SelectMfaComponent {

    @Output() onStepChange = new EventEmitter<any>();
    @Input() allowedMFATypes!: string[];
    selectedMfaMethod: string = '';

    inProgress = false;

    constructor(private messageService: MessageService,
                private localizedErrorTranslateService: LocalizedErrorTranslateService,
                private authService: AuthService) {
    }

    async onSubmit() {
        this.inProgress = true;
        try {
            const {isSignedIn, nextStep} = await this.authService.confirmSignIn({
                challengeResponse: this.selectedMfaMethod
            });
            this.onStepChange.emit({isSignedIn: isSignedIn, nextStep: nextStep});
        } catch (error) {
            this.localizedErrorTranslateService.handleError(error);
        }
        this.inProgress = false;
    }
}
