<div class="flex justify-content-center">
  <form (ngSubmit)="confirmResetForm.form.valid && onConfirmResetCode()" #confirmResetForm="ngForm" class="surface-card p-4 rcm-shadow border-round w-11 md:w-8 lg:w-8 xl:w-4">
    <div class="mb-5">
      <div class="text-700 text-xl font-normal mb-3">{{ 'ui.forgot.password.confirm.header' | translate }}</div>
      <span class="text-700 font-normal line-height-3">{{ 'ui.password.change.description.5' | translate }}</span>
      <div class="text-700 font-italic line-height-3 mt-3"><i class="pi pi-info-circle mr-2 text-700"></i>{{
              'ui.password.pattern.info' | translate }}</div>
    </div>

    <div>
      <div>
        <label for="newPassword" class="block text-700 font-bold mb-2">{{ 'ui.password.change.new.password.new' | translate }}</label>
        <p-password id="newPassword" name="newPassword" [(ngModel)]="newPassword" #newPasswordField="ngModel"
                    required [pattern]="passwordPattern" class="w-full mb-3"
                    [style]="{'width':'100%'}"
                    [inputStyle]="{'width':'100%'}"
                    [toggleMask]="true"
                    [feedback]="false"></p-password>
        <div *ngIf="newPasswordField.errors?.['required'] && (newPasswordField.touched || confirmResetForm.submitted)"
             class="alert mb-4 mt-2">{{ 'ui.password.reset.new.password.description.1' | translate }}</div>
        <div *ngIf="newPasswordField.errors?.['pattern'] && (newPasswordField.touched || confirmResetForm.submitted)"
             class="alert mb-4 mt-2">{{ 'ui.password.pattern.info' | translate }}</div>
      </div>

      <div class="mt-4">
        <label for="confirmPassword"
               class="block text-700 font-bold mb-2">{{ 'ui.password.change.new.password.confirm' | translate }}</label>
        <p-password id="confirmPassword" name="confirmPassword" [(ngModel)]="confirmPassword"
                    #confirmPasswordField="ngModel" required [pattern]="passwordPattern" class="w-full mb-3"
                    [ngModelOptions]="{updateOn: 'blur'}"
                    [style]="{'width':'100%'}"
                    [inputStyle]="{'width':'100%'}"
                    [toggleMask]="true"
                    [feedback]="false"></p-password>
        <div *ngIf="confirmPasswordField.errors?.['required'] && (confirmPasswordField.touched || confirmResetForm.submitted)"
             class="alert mb-4 mt-2">{{ 'ui.password.confirmation.required' | translate }}</div>
        <div *ngIf="confirmPasswordField.errors?.['pattern'] && (confirmPasswordField.touched || confirmResetForm.submitted)"
             class="alert mb-4 mt-2">{{ 'ui.password.pattern.info' | translate }}</div>
        <div *ngIf="newPassword && confirmPassword && newPassword !== confirmPassword && (confirmPasswordField.touched || confirmResetForm.submitted)"
             class="alert mb-4 mt-2">{{ 'ui.password.change.description.3' | translate }}</div>
      </div>

      <div class="mt-4">
        <label for="code" class="block text-700 font-bold mb-2">{{ 'ui.password.reset.new.password.code' | translate }}</label>
        <input id="code" name="code" type="text" [(ngModel)]="confirmationCode" #confirmationCodeField="ngModel" required pInputText class="w-full mb-3">
        <div *ngIf="confirmationCodeField.errors?.['required'] && (confirmationCodeField.touched || confirmResetForm.submitted)" class="alert mb-4">{{ 'ui.password.change.error' | translate }}</div>
      </div>

      <button pButton
              [label]="inProgress ? ('ui.please.wait' | translate) : ('ui.password.reset.button.label' | translate)"
              [icon]="inProgress ? 'pi pi-spin pi-spinner' : ''"
              [disabled]="inProgress"
              class="w-6 col-offset-3 text-900 custom-p-button"></button>
    </div>
  </form>
</div>
