<div class="flex justify-content-center">
    <form (ngSubmit)="loginForm.form.valid && onSubmit()" #loginForm="ngForm"
          class="surface-card p-4 rcm-shadow border-round w-11 md:w-8 lg:w-8 xl:w-4">
        <div class="mb-6">
            <div class="text-700 text-xl font-normal mb-3">{{ 'ui.registration.subject' | translate }}</div>
            <span class="text-700 font-normal line-height-3">{{ 'ui.registration.description' | translate }}</span>
        </div>

        <div>
            <div>
                <label for="email" class="block text-700 font-bold mb-2">{{ 'ui.registration.email.1' | translate }}</label>
                <input id="email" name="email" type="email" [(ngModel)]="email" #emailField="ngModel" required email
                       pInputText class="w-full mb-3">
                <div *ngIf="emailField.errors?.['required'] && (emailField.touched || loginForm.submitted)"
                     class="alert mb-4">{{ 'ui.registration.email.2' | translate }}</div>
                <div *ngIf="emailField.errors?.['email'] && (emailField.touched || loginForm.submitted)"
                     class="alert mb-4">{{ 'ui.registration.email.error' | translate }}</div>
            </div>

            <div class="mt-3">
                <label for="password"
                       class="block text-700 font-bold mb-2">{{ 'ui.registration.password.1' | translate }}</label>
                <p-password id="password" name="password" [(ngModel)]="password" #passwordField="ngModel" required
                            class="mb-3"
                            [feedback]="false"
                            [style]="{'width':'100%'}"
                            [inputStyle]="{'width':'100%'}"
                            [toggleMask]="true"></p-password>
                <div *ngIf="passwordField.errors?.['required'] && (passwordField.touched || loginForm.submitted)"
                     class="alert mt-3 mb-4">{{ 'ui.registration.password.2' | translate }}</div>
            </div>

            <div class="flex align-items-center mb-6">
                <a class="no-underline mt-4 text-700 font-bold cursor-pointer"
                   [appLocalizedRouterLink]="'forgot-password'">
                    {{ 'ui.registration.password.3' | translate }}
                    <i class="pi pi-arrow-right ml-2 text-700"></i>
                </a>
            </div>

            <button pButton
                    (click)="loginForm.form.valid ? onSubmit() : loginForm.form.markAsTouched()"
                    [label]="inProgress ? ('ui.please.wait' | translate) : ('ui.registration.button.label' | translate)"
                    [icon]="inProgress ? 'pi pi-spin pi-spinner' : ''"
                    [disabled]="inProgress"
                    class="w-6 col-offset-3 text-900 custom-p-button"></button>
        </div>
    </form>
</div>
