import {Amplify, ResourcesConfig} from "aws-amplify";
import {environment} from "../../environments/environment";
import {LocalizedRouterService} from "./localized-router.service";
import {Injectable} from "@angular/core";
import {CookieOptions, CookieService} from "ngx-cookie-service";

@Injectable()
export class AmplifyInitService {

    constructor(private routerService: LocalizedRouterService,
                private cookieService: CookieService) {
    }

    init(): void {
        const config: ResourcesConfig = Amplify.getConfig();
        if (!config?.Auth?.Cognito?.userPoolId || !config?.Auth?.Cognito?.userPoolClientId || !config?.Auth?.Cognito?.userPoolEndpoint) {
            let queryStringParams: URLSearchParams = new URLSearchParams(window.location.search);
            let clientId: string = queryStringParams.get("client_id") || this.cookieService.get("client_id") || '';

            if (!clientId) {
                this.routerService.navigate(['/error'], {queryParams: {error: 'client id is missing'}});
            } else {
                const cookieOptions: CookieOptions = {
                    secure: true,
                    sameSite: 'Strict',
                }
                this.cookieService.set("client_id", clientId, cookieOptions);
                Amplify.configure({
                    Auth: {
                        Cognito: {
                            userPoolId: environment.userPoolId,
                            userPoolClientId: clientId,
                            userPoolEndpoint: `${window.location.protocol}//${window.location.hostname + environment.authenticationPath}`
                        }
                    }
                });
            }
        }
    }
}