<div class="flex justify-content-center">
  <form (ngSubmit)="confirmCodeForm.form.valid && onConfirmSignIn()" #confirmCodeForm="ngForm" class="surface-card p-4 rcm-shadow border-round w-11 md:w-8 lg:w-8 xl:w-4">
    <div class="mb-5">
      <div class="text-700 text-xl font-normal mb-3">{{ 'ui.mfa.confirm.header' | translate}}</div>
      <span class="text-700 font-normal line-height-3">{{ 'ui.mfa.description.5' | translate}}</span>
    </div>

    <div>
      <label for="code" class="block text-700 font-bold mb-2">{{ 'ui.mfa.onetime.password.1' | translate}}</label>
      <input id="code" name="code" type="text" [(ngModel)]="code" #codeField="ngModel" required pInputText class="w-full mb-3">
      <div *ngIf="codeField.errors?.['required'] && (confirmCodeForm.submitted || codeField.touched)" class="alert mb-4">{{ 'ui.mfa.onetime.password.2' | translate }}</div>

      <button pButton
              (click)="confirmCodeForm.form.valid ? onConfirmSignIn() : confirmCodeForm.form.markAsTouched()"
              [label]="inProgress ? ('ui.please.wait' | translate) : ('ui.verify.button.label' | translate)"
              [icon]="inProgress ? 'pi pi-spin pi-spinner' : ''"
              [disabled]="inProgress"
              class="w-6 col-offset-3 text-900 custom-p-button"></button>
    </div>
  </form>
</div>
