import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocalizedErrorTranslateService} from "../../services/localized-error-translate.service";
import {AuthService} from "../../services/auth.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-setup-totp',
    templateUrl: './setup-totp.component.html',
    styleUrl: './setup-totp.component.css'
})
export class SetupTotpComponent implements OnInit {

    @Output() onStepChange = new EventEmitter<any>();
    @Input() foreChangePasswordStep: string = 'false';

    inProgress = false;

    @Input() authQrCode: string = '';
    totpCode: string = '';
    dynamicDescriptions: string[] = [];

    constructor(private localizedErrorTranslateService: LocalizedErrorTranslateService,
                private authService: AuthService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang).subscribe(translations => {
            this.dynamicDescriptions = Object.keys(translations)
                .filter(key => /^ui\.mfa\.setup\.description\.\d+$/.test(key) && !key.endsWith('.1'))
                .map(key => translations[key]);
        });
    }

    async onConfirmSignIn() {
        this.inProgress = true;

        try {
            const {isSignedIn, nextStep} = await this.authService.confirmSignIn({
                challengeResponse: this.totpCode,
                options: {
                    authFlowType: 'USER_SRP_AUTH',
                    clientMetadata: {
                        foreChangePasswordStep: this.foreChangePasswordStep,
                    }
                }
            });

            this.onStepChange.emit({isSignedIn: isSignedIn, nextStep: nextStep});
        } catch (error) {
            this.localizedErrorTranslateService.handleError(error);
        }
        this.inProgress = false;
    }
}
