import {Injectable} from '@angular/core';
import {Amplify} from "aws-amplify";

@Injectable()
export class InterceptorService {

    public redirectInProgress = false;

    interceptCognito(): void {
        const originalFetch = window.fetch;

        window.fetch = async (...args: Parameters<typeof fetch>) => {
            const response = await originalFetch(...args);

            const urlString = args[0] instanceof Request ? args[0].url : args[0].toString();

            if (response.status === 200 && urlString.includes(Amplify.getConfig().Auth?.Cognito?.userPoolEndpoint!)) {

                const responseCopy = response.clone();
                const body = await responseCopy.json();

                console.log('Body:', body);

                const redirect: string | null = response.headers.get('X-Rcm-Redirect');
                const code: string | null = response.headers.get('X-Rcm-Code');
                const state: string | null = response.headers.get('X-Rcm-State');
                const clientId: string | null = response.headers.get('X-Rcm-Client-id');

                console.log(`Redirect: ${redirect}, Code: ${code}, State: ${state}`)

                if (body && redirect && code && clientId) {
                    const stateQueryParameter: string = state ? '&state=' + state : '';
                    this.redirectInProgress = true;
                    window.location.href = redirect + '?code=' + code + stateQueryParameter;
                }
            }

            return response;
        };
    }
}
