<p-toolbar>
  <div class="p-toolbar-group-left lg:ml-4 xl:mx-15 sm:ml-0 md:ml-0">
      <a [href]="logoLink">
          <img [src]="logoSrc" alt="raiffeisen-capital-logo"
               style="height:3rem;min-height:3rem;width:calc(3rem * 4.721714687280393);min-width:calc(3rem *
           4.721714687280393);"/>
      </a>
  </div>
  <div class="p-toolbar-group-right lg:mr-4 xl:mx-15 sm:mr-0 md:mr-0">
    <p-dropdown class="lang-dropdown" [options]="languages" [(ngModel)]="selectedLanguage" (onChange)="changeLanguage($event.value)" placeholder="Select Language"></p-dropdown>
  </div>
</p-toolbar>
