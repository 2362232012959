import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class LocaleService {

    public static readonly DEFAULT_LOCALE:string = 'at-en';
    private localeSubject:BehaviorSubject<string|null> = new BehaviorSubject<string | null>(null);
    locale$:Observable<string|null> = this.localeSubject.asObservable();
    locale: string = '';

    constructor(private route: ActivatedRoute,
                private router: Router,
    private location:Location) {

        if(!this.locale){
            this.locale = this.extractLocaleFromPath(location.path() || LocaleService.DEFAULT_LOCALE);
            this.localeSubject.next(this.locale);
        }

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.locale = this.extractLocaleFromPath(event.urlAfterRedirects);
                this.localeSubject.next(this.locale);
            }
        });
    }

    extractLocaleFromPath(url: string): string {
        // Angenommen, die URL hat die Form '/<locale>/...', z.B. '/at-en/login'
        const pathMatch = url.match(/^\/([a-z]{2}(?:-[a-z]{2})?)\//i);
        return pathMatch ? pathMatch[1] : LocaleService.DEFAULT_LOCALE;
    }

    extractLanguageFromPath(url: string): string | null {
        const pathMatch = url.match(/\/[a-z]{2}-([a-z]{2})\//i);
        return pathMatch ? pathMatch[1] : null; // 'en' als Fallback-Sprache
    }
}
