import {Component, Input, OnInit} from '@angular/core';
import {SelectItem} from "primeng/api";
import {AuthUser} from "aws-amplify/src/auth";
import {LanguageService} from "../../services/language.service";
import {Location} from '@angular/common';
import {LocalizedRouterService} from "../../services/localized-router.service";

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrl: './topbar.component.css'
})
export class TopbarComponent implements OnInit {
    languages: SelectItem[] = [
        {label: 'English', value: 'en', icon: 'pi pi-user'},
        {label: 'Deutsch', value: 'de', icon: 'pi pi-logout'}
    ];
    selectedLanguage: string | null = '';
    user: AuthUser | null = null;
    @Input() logoSrc!: string | null;
    @Input() logoLink!: string | null;

    constructor(private routerService: LocalizedRouterService,
                private languageService: LanguageService,
                private location: Location) {
    }

    ngOnInit(): void {
        this.languageService.language$.subscribe(lang => {
            this.selectedLanguage = lang;
        });
    }

    changeLanguage(lang: string) {
        this.languageService.changeLanguage(lang);
        this.processUrlForLanguageChange(this.location.path(), lang);

    }

    processUrlForLanguageChange(currentUrl: string, newLang: string): void {
        const newUrl = currentUrl.replace(/(\/[a-z]{2}-)[a-z]{2}(\/.*)$/, `$1${newLang}$2`);
        this.routerService.navigateByUrl(newUrl, {replaceUrl: true});
    }
}
