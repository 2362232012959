import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from "./app.component";
import {RouterOutlet} from "@angular/router";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {ToolbarModule} from "primeng/toolbar";
import {ToastModule} from 'primeng/toast';
import {ButtonModule} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CardModule} from "primeng/card";
import {InputTextModule} from "primeng/inputtext";
import {CheckboxModule} from "primeng/checkbox";
import {RippleModule} from "primeng/ripple";
import {AppRoutingModule} from "./app-routing.module";
import {MessageService} from "primeng/api";
import {QRCodeModule} from "angularx-qrcode";
import {HttpClient, HttpClientModule, provideHttpClient} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {DropdownModule} from "primeng/dropdown";
import {MenuModule} from "primeng/menu";
import {TopbarComponent} from "./components/topbar/topbar.component";
import {ConfirmForgotPasswordComponent} from "./components/confirm-forgot-password/confirm-forgot-password.component";
import {ForgotPasswordComponent} from "./components/forgot-password/forgot-password.component";
import {ForgotPasswordPageComponent} from "./pages/forgot-password-page/forgot-password-page.component";
import {LoginPageComponent} from "./pages/login-page/login-page.component";
import {ForceChangePasswordComponent} from "./components/force-change-password/force-change-password.component";
import {SignInComponent} from "./components/sign-in/sign-in.component";
import {SetupTotpComponent} from "./components/setup-totp/setup-totp.component";
import {FooterComponent} from "./components/footer/footer.component";
import {CustomLoaderFactory} from "./util/custom-translate-loader";
import {LocalizedRouterLinkDirective} from "./directives/localized-router-link.directive";
import {PasswordModule} from "primeng/password";
import {SelectMfaComponent} from "./components/select-mfa/select-mfa.component";
import {RadioButtonModule} from "primeng/radiobutton";
import {ConfirmCodeComponent} from "./components/confirm-code/confirm-code.component";
import Client from "storyblok-js-client";
import {InterceptorService} from "./interceptors/interceptor.service";
import {ErrorPageComponent} from "./pages/error-page/error-page.component";
import {AmplifyInitService} from "./services/amplify-init.service";
import {InputOtpModule} from "primeng/inputotp";

@NgModule({
    declarations: [
        AppComponent,
        LoginPageComponent,
        ForgotPasswordPageComponent,
        TopbarComponent,
        ForgotPasswordComponent,
        ConfirmForgotPasswordComponent,
        ConfirmCodeComponent,
        ForceChangePasswordComponent,
        SignInComponent,
        SetupTotpComponent,
        FooterComponent,
        LocalizedRouterLinkDirective,
        SelectMfaComponent,
        ErrorPageComponent
    ],
    imports: [
        BrowserModule,
        RouterOutlet,
        CommonModule,
        ToolbarModule,
        ButtonModule,
        DialogModule,
        BrowserAnimationsModule,
        FormsModule,
        CardModule,
        InputTextModule,
        CheckboxModule,
        RippleModule,
        AppRoutingModule,
        ToastModule,
        QRCodeModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: CustomLoaderFactory,
                deps: [HttpClient]
            }
        }),
        DropdownModule,
        MenuModule,
        PasswordModule,
        RadioButtonModule,
        ReactiveFormsModule,
        NgOptimizedImage,
        InputOtpModule,
    ],
    providers: [
        MessageService,
        InterceptorService,
        AmplifyInitService,
        {
            provide: Client,
            useValue: new Client({})
        }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
