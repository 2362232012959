import {Component, OnInit} from '@angular/core';
import {LoginSteps} from "../../models/login-steps.enum";
import {LocalizedRouterService} from "../../services/localized-router.service";
import {AmplifyInitService} from "../../services/amplify-init.service";

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrl: './login-page.component.css'
})

export class LoginPageComponent implements OnInit {

    LoginSteps = LoginSteps;
    activeStep: LoginSteps = LoginSteps.SIGN_IN;
    foreChangePasswordStep: string = 'false';

    email?: string | undefined;
    authQrCode: string = 'authQrCode';
    allowedMFATypes: string[] = [];
    missingAttributes: string[] = [];

    constructor(private routerService: LocalizedRouterService,
                private amplifyInitService: AmplifyInitService) {
    }

    ngOnInit(): void {
        this.amplifyInitService.init();
    }

    handleStepChange(event: any) {
        this.missingAttributes = event.nextStep.missingAttributes;
        console.log('handleStepChange', event);
        if (!event.isSignedIn) {
            switch (event.nextStep.signInStep) {
                case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
                    this.missingAttributes = event.nextStep.missingAttributes;
                    this.activeStep = LoginSteps.NEW_PASSWORD_REQUIRED;
                    this.foreChangePasswordStep = 'true';
                    break;
                case 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP':
                    const setupUri = event.nextStep.totpSetupDetails.getSetupUri('rcm-centralized-login');
                    console.log(setupUri.href);
                    this.authQrCode = setupUri.href;
                    this.activeStep = LoginSteps.SETUP_TOTP;
                    break;
                case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
                case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
                    this.activeStep = LoginSteps.CONFIRM_CODE;
                    break;
                case 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION':
                    this.allowedMFATypes = event.nextStep.allowedMFATypes;
                    this.activeStep = LoginSteps.SELECT_MFA;
                    break;
                default:
                    this.routerService.navigate(['/error'], { queryParams: { error: 'Unknown step!'}});
            }
        }
    }
}
