import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';
import {LocalizedErrorTranslateService} from "../../services/localized-error-translate.service";
import {AuthService} from "../../services/auth.service";

@Component({
    selector: 'app-force-change-password',
    templateUrl: './force-change-password.component.html',
    styleUrls: ['./force-change-password.component.css']
})
export class ForceChangePasswordComponent implements OnInit {
    @Output() onStepChange = new EventEmitter<any>();
    @Input() missingAttributes!: string[];
    @Input() email?: string | undefined;

    inProgress:boolean = false;


    passwordPattern: string = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\^$*.[\\]{}()?\"!@#%&/\\\\,<>'':;|_~`+=-]).{8,}$";
    phoneNumberPattern:string = '^\\+[1-9][0-9]{8,24}$';
    form: FormGroup;

    constructor(
        private fb: FormBuilder,
        private localizedErrorTranslateService: LocalizedErrorTranslateService,
        private authService: AuthService
    ) {
        this.form = this.fb.group({
            newPassword: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
            confirmPassword: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
            attributes: this.fb.array([])
        }, {validators: this.passwordMatchValidator});
    }

    ngOnInit() {
        this.addMissingAttributes();
    }

    get attributes() {
        return this.form.get('attributes') as FormArray;
    }

    getFormControl(control: AbstractControl): FormControl {
        return control as FormControl;
    }

    addMissingAttributes() {
        this.missingAttributes.forEach(attr => {
            if (attr === 'phone_number') {
                this.attributes.push(this.fb.control('', [Validators.required, Validators.pattern(this.phoneNumberPattern)]));
            } else {
                this.attributes.push(this.fb.control('', Validators.required));
            }
        });
    }

    passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
        const newPassword = control.get('newPassword');
        const confirmPassword = control.get('confirmPassword');
        return newPassword && confirmPassword && newPassword.value === confirmPassword.value ? null : {'passwordMismatch': true};
    }

    async onChangePassword() {
        this.inProgress = true;

        if (!this.form.valid) {
            return;
        }

        try {
            const attributesArray = this.form.get('attributes')?.value;

            const filledUserAttributes = attributesArray.reduce((obj: any, item: any, index: any) => {
                obj[this.missingAttributes[index]] = item;
                return obj;
            }, {});

            if (this.email) {
                filledUserAttributes['email'] = this.email;
            }

            const {isSignedIn, nextStep} = await this.authService.confirmSignIn({
                challengeResponse: this.form.value.confirmPassword,
                options:
                    {
                        userAttributes: filledUserAttributes
                    }
            });

            this.onStepChange.emit({isSignedIn: isSignedIn, nextStep: nextStep});
        } catch (error) {
            this.localizedErrorTranslateService.handleError(error);
        }

        this.inProgress = false;
    }
}
