import {TranslateLoader} from '@ngx-translate/core';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import Client from "storyblok-js-client";

import {environment} from '../../environments/environment';

export class CustomTranslateLoader implements TranslateLoader {
    private sbClient: Client = new Client({});

    public getTranslation(lang: string): Observable<any> {
        return from(this.sbClient.getAll(environment.storyblokSlug, {
            datasource: environment.storyblokDataSourceLogin,
            token: environment.sbToken,
            dimension: lang,
            per_page: 1000,
            cv: Date.now()
        })).pipe(
            map(response => this.transformResponse(response, lang))
        );
    }

    private transformResponse(response: any, lang: string): Record<string, string> {
        const transformed: Record<string, string> = {};

        response.forEach((entry: any) => {
            transformed[entry.name] = entry.dimension_value || entry.value
        });

        return transformed;
    }
}

export function CustomLoaderFactory() {
    return new CustomTranslateLoader();
}