import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {TranslateService} from "@ngx-translate/core";
import { Location } from '@angular/common';
import {LocaleService} from "./locale.service";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  static readonly DEFAULT_LANGUAGE = 'en';
  static readonly SELECTED_LANG_KEY = 'selectedLanguage';
  static readonly SUPPORTED_LANGUAGES = ['en', 'de'];

  languageSubject = new BehaviorSubject<string | null>(null);
  language$ = this.languageSubject.asObservable();

  constructor(private translateService: TranslateService,
              private location: Location,
              private localeService: LocaleService) {
    this.initializeTranslation();
  }

  initializeTranslation(): void {
    this.translateService.addLangs(LanguageService.SUPPORTED_LANGUAGES);
    this.translateService.setDefaultLang(LanguageService.DEFAULT_LANGUAGE);

    const pathLanguage = this.localeService.extractLanguageFromPath(this.location.path())
    const savedLanguage = localStorage.getItem(LanguageService.SELECTED_LANG_KEY);
    const browserLang = this.translateService.getBrowserLang() || LanguageService.DEFAULT_LANGUAGE;

    const langToUse = pathLanguage || savedLanguage || (this.translateService.getLangs().includes(browserLang) ? browserLang : LanguageService.DEFAULT_LANGUAGE);
    this.translateService.use(langToUse);
    this.languageSubject.next(langToUse);
  }

  changeLanguage(lang: string) {
    this.translateService.use(lang);
    localStorage.setItem(LanguageService.SELECTED_LANG_KEY, lang);
    this.languageSubject.next(lang)
  }
}
