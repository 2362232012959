import {Component, OnInit} from '@angular/core';
import {PasswordResetSteps} from "../../models/forgot-password-steps.enum";
import {AmplifyInitService} from "../../services/amplify-init.service";

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrl: './forgot-password-page.component.css'
})
export class ForgotPasswordPageComponent implements OnInit {
  PasswordResetSteps = PasswordResetSteps;
  activeStep = {step: PasswordResetSteps.PASSWORD_RESET, emailAddress: ''};

  constructor(private amplifyInitService: AmplifyInitService) {
  }

  ngOnInit(): void {
    this.amplifyInitService.init();
  }
}
