<div class="flex justify-content-center">
    <form (ngSubmit)="mfaForm.form.valid && onSubmit()" #mfaForm="ngForm"
          class="surface-card p-4 rcm-shadow border-round w-11 md:w-8 lg:w-8 xl:w-4">

        <div class="mb-6">
            <div class="text-700 text-xl font-normal mb-3">{{ 'ui.select.mfa.header' | translate }}</div>
            <span
                    class="text-700 font-normal line-height-3">{{ 'ui.mfa.selection.type' |
                translate }}</span>
        </div>

        <div class="mb-6">
            <label class="block text-700 font-bold mb-2">{{ 'ui.mfa.type' | translate }}</label>
            <div *ngFor="let method of allowedMFATypes; index as i" class="mt-3">
                <p-radioButton name="mfaMethod"
                               [value]="method"
                               [(ngModel)]="selectedMfaMethod"
                               required
                               [label]="method | translate"></p-radioButton>
            </div>
        </div>

        <button pButton
                [label]="inProgress ? ('ui.please.wait' | translate) : ('ui.next.button.label' | translate)"
                [icon]="inProgress ? 'pi pi-spin pi-spinner' : ''"
                [disabled]="inProgress || mfaForm.invalid"
                class="w-6 col-offset-3 text-900 custom-p-button"></button>
    </form>
</div>
