import {Injectable} from '@angular/core';
import {
    confirmSignIn as amplifyConfirmSignIn,
    signIn as amplifySignIn
} from 'aws-amplify/auth';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Amplify } from '@aws-amplify/core';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private readonly passwordForgotPath = `/oauth2/forgot-password`;

    private readonly httpOptions: {};

    constructor(private http: HttpClient) {

        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }
    }

    signIn(input: any) {
        return amplifySignIn(input);
    }

    confirmSignIn(input: any) {
        return amplifyConfirmSignIn(input);
    }

    forgotPassword(input: any) {
        return this.http.post(this.passwordForgotPath,  Object.assign(input, {client_id: Amplify.getConfig().Auth?.Cognito.userPoolClientId}), this.httpOptions);
    }

    confirmForgotPassword(input: any) {
        return this.http.post(this.passwordForgotPath,  Object.assign(input, {client_id: Amplify.getConfig().Auth?.Cognito.userPoolClientId}), this.httpOptions);
    }
}
