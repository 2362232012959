import {Component, EventEmitter, Output} from '@angular/core';
import {LocalizedErrorTranslateService} from "../../services/localized-error-translate.service";
import {AuthService} from "../../services/auth.service";

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrl: './sign-in.component.css'
})
export class SignInComponent {
    @Output() onStepChange = new EventEmitter<any>();

    inProgress = false;

    email = '';
    password = '';

    constructor(private localizedErrorTranslateService: LocalizedErrorTranslateService,
                private authService: AuthService) {
    }

    async onSubmit() {
        this.inProgress = true;
        try {
            const {isSignedIn, nextStep} = await this.authService.signIn({
                username: this.email,
                password: this.password,
                options: {
                    authFlowType: 'USER_SRP_AUTH'
                }
            });
            this.onStepChange.emit({isSignedIn: isSignedIn, nextStep: nextStep, email: this.email });
        } catch (error) {
            this.localizedErrorTranslateService.handleError(error);
        }

        this.inProgress = false;
    }
}
