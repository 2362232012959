import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {environment} from "../../../environments/environment";
import {FooterData} from "../../models/footer-data.model";
import {LocaleService} from "../../services/locale.service";
import {Subscription} from "rxjs";
import Client from "storyblok-js-client";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.css'
})
export class FooterComponent implements OnInit, OnDestroy {

    footerData: FooterData = new FooterData();
    localeSubscription!: Subscription;
    @Input() logoSrc!: string | null;

    constructor(private localeService: LocaleService, private sbClient: Client) {
    }

    ngOnInit(): void {
        this.localeSubscription = this.localeService.locale$.subscribe(locale => {
            this.sbClient.getAll(environment.storyblokSlug, {
                datasource: environment.storyblokDataSourceFooter,
                token: environment.sbToken,
                dimension: locale || LocaleService.DEFAULT_LOCALE,
                per_page: 1000,
                cv: Date.now()
            }).then(response => {
                this.transformResponseToFooterData(response);
            });
        })
    }

    transformResponseToFooterData(response: any): void {
        const allowedLinks = [
            'footer.bottomLinks.link6', // Disclaimer
            'footer.bottomLinks.link9', // Imprint
            'footer.bottomLinks.link10' // Data privacy notice
        ];

        const linkObjects: { [key: string]: { name: string; link: string } } = {};

        response.forEach((entry: any) => {
            const isAllowedLink = allowedLinks.some(allowedLink => entry.name.startsWith(allowedLink));
            if (isAllowedLink) {
                const [base, type] = entry.name.split('.').slice(-2);

                if (!linkObjects[base]) {
                    linkObjects[base] = {name: '', link: ''};
                }

                if (type === 'label') {
                    linkObjects[base].name = entry.dimension_value || entry.value;
                } else if (type === 'link') {
                    linkObjects[base].link = environment.rcmHost + (entry.dimension_value || entry.value);
                }
            } else if (entry.name === 'footer.copyRightText') {
                this.footerData.copyrightText = entry.dimension_value || entry.value;
            } else if (entry.name === 'footer.infoText') {
                this.footerData.infoText = entry.dimension_value || entry.value;
            }
        });

        this.footerData.footerLinks = Object.values(linkObjects);
    }

    ngOnDestroy(): void {
        if (this.localeSubscription) {
            this.localeSubscription.unsubscribe();
        }
    }
}
