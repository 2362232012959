<footer class="flex flex-column rcm-shadow p-3 footer">
    <div class="flex flex-column align-items-start xl:mx-15">
        <div class="mb-2">
            <img [src]="logoSrc" style="height:2.625rem;min-height:2.625rem;width:calc(2.625rem * 4.721714687280393);min-width:calc(2.625rem * 4.721714687280393);">
        </div>
        <div class="col-12 lg:col-4 p-0">
            <span class="text-sm">{{ footerData.infoText }}</span>
        </div>
    </div>
    <div class="my-3 xl:mx-15" style="height: 1px; background-color: white;"></div>
    <div class="flex align-items-center justify-content-between xl:mx-15 flex-wrap">
        <div class="flex flex-row">
            <ng-container *ngFor="let link of footerData.footerLinks">
                <a [href]="link.link" target="_blank" class="mr-2 text-white text-sm">{{ link.name }}</a>
            </ng-container>
        </div>
        <span class="text-sm w-full md:w-auto mt-3 md:mt-0">{{ footerData.copyrightText }}</span>
    </div>
</footer>
