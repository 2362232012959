<div class="flex justify-content-center">
    <form (ngSubmit)="setupForm.form.valid && onConfirmSignIn()" #setupForm="ngForm"
          class="surface-card p-4 rcm-shadow border-round w-11 md:w-8 lg:w-8 xl:w-4">
        <div class="mb-5">
            <div class="text-700 text-xl font-normal mb-3">{{ 'ui.mfa.subject' | translate }}</div>
            <span class="text-700 font-normal line-height-3">{{ 'ui.mfa.description.1' | translate }}</span>
            <ul>
                <li><span class="text-700 font-normal line-height-3">{{ 'ui.mfa.description.2' | translate }}</span>
                </li>
                <li><span class="text-700 font-normal line-height-3">{{ 'ui.mfa.description.3' | translate }}</span>
                </li>
                <li><span class="text-700 font-normal line-height-3">{{ 'ui.mfa.description.4' | translate }}</span>
                </li>
            </ul>
        </div>

        <div>
            <div class="flex justify-content-center">
                <qrcode [qrdata]="authQrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
            </div>

            <label for="initialCode"
                   class="block text-700 font-bold mb-2">{{ 'ui.mfa.onetime.password.1' | translate }}</label>
            <input id="initialCode" name="initialCode" type="text" [(ngModel)]="totpCode" #initialCodeField="ngModel"
                   required pInputText class="w-full mb-3">
            <div *ngIf="initialCodeField.errors?.['required'] && (initialCodeField.touched || setupForm.submitted)"
                 class="alert mb-4">{{ 'ui.mfa.onetime.password.2' | translate }}
            </div>

            <button pButton
                    (click)="setupForm.form.valid ? onConfirmSignIn() : setupForm.form.markAsTouched()"
                    [label]="inProgress ? ('ui.please.wait' | translate) : ('ui.mfa.button.label' | translate)"
                    [icon]="inProgress ? 'pi pi-spin pi-spinner' : ''"
                    [disabled]="inProgress"
                    class="w-6 col-offset-3 text-900 custom-p-button"></button>
        </div>
    </form>
</div>
