import { Injectable } from '@angular/core';
import Client from 'storyblok-js-client';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LogoService {
    private sbClient: Client;

    constructor() {
        this.sbClient = new Client({
            accessToken: environment.sbToken
        });
    }

    loadLogoData() {
        return this.sbClient.getStory(environment.storyblokPortalSlug, {
            token: environment.sbToken,
        }).then(response => {
            const logoData = {
                logoSrc: response.data.story.content['logo'].filename,
                logoLink: response.data.story.content['logo_link'].url,
                footerLogoSrc: response.data.story.content['footer_logo'].filename
            };
            return logoData;
        });
    }
}
