import {Component, EventEmitter, Output} from '@angular/core';
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {PasswordResetSteps} from "../../models/forgot-password-steps.enum";
import {LocalizedErrorTranslateService} from "../../services/localized-error-translate.service";
import {AuthService} from "../../services/auth.service";

@Component({
    selector: 'app-password-forgot',
    templateUrl: './forgot-password.component.html',
    styleUrl: './forgot-password.component.css'
})
export class ForgotPasswordComponent {
    @Output() onStepChange = new EventEmitter<{ step: PasswordResetSteps, emailAddress: any }>();

    inProgress: boolean = false;
    emailAddress: string = '';

    constructor(private messageService: MessageService,
                private translationService: TranslateService,
                private localizedErrorTranslateService: LocalizedErrorTranslateService,
                private authService: AuthService) {
    }

    private forgotPasswordComplete = (): void => {
        this.onStepChange.emit({
            step: PasswordResetSteps.PASSWORD_RESET_CONFIRM,
            emailAddress: this.emailAddress,
        });
        this.messageService.add({
            severity: 'info',
            detail: this.translationService.instant('ui.password.change.description.4')
        });
    }

    private handleError = (error: {}): void => {
        this.localizedErrorTranslateService.handleError(error)
    }

    async onResetPassword() {
        this.inProgress = true;
        this.authService.forgotPassword({username: this.emailAddress}).subscribe({
            complete: (): void => this.forgotPasswordComplete(),
            error: (error): void => this.handleError(error),
        });

        this.inProgress = false;
    }
}
