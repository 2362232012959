import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginPageComponent} from './pages/login-page/login-page.component';
import {ForgotPasswordPageComponent} from "./pages/forgot-password-page/forgot-password-page.component";
import {ErrorPageComponent} from "./pages/error-page/error-page.component";

const routes: Routes = [
  {path: ':locale/login', component: LoginPageComponent },
  {path: ':locale/forgot-password', component: ForgotPasswordPageComponent},
  {path: ':locale/error', component: ErrorPageComponent},
  {path: '', redirectTo: '/at-en/login', pathMatch: 'full'},
  {path: '**', redirectTo: '/at-en/error'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
