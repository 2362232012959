<div class="flex justify-content-center">
  <form (ngSubmit)="resetForm.form.valid && onResetPassword()" #resetForm="ngForm" class="surface-card p-4 rcm-shadow border-round w-11 md:w-8 lg:w-8 xl:w-4">
    <div class="mb-5">
      <div class="text-700 text-xl font-normal mb-3">{{ 'ui.password.reset.subject' | translate }}</div>
      <span class="text-700 font-normal line-height-3">{{ 'ui.password.reset.description.1' | translate }}</span>
    </div>

    <div>
      <label for="emailAddress" class="block text-700 font-bold mb-2">{{ 'ui.registration.email.1' | translate }}</label>
      <input id="emailAddress" name="emailAddress" type="text" [(ngModel)]="emailAddress" #emailField="ngModel" required email pInputText class="w-full mb-3">
      <div *ngIf="emailField.errors?.['required'] && (emailField.touched || resetForm.submitted)" class="alert mb-4">{{ 'ui.registration.email.1' | translate }}</div>
      <div *ngIf="emailField.errors?.['email'] && (emailField.touched || resetForm.submitted)" class="alert mb-4">{{
              'ui.registration.email.error' | translate }}</div>

      <div class="flex mt-2">
        <a class="font-bold no-underline text-700 cursor-pointer w-3 mt-2" [appLocalizedRouterLink]="'login'">
          <i class="pi pi-arrow-left mr-2 text-700"></i> {{ 'back' | translate }}
        </a>

        <button pButton
                [label]="inProgress ? ('pleaseWait' | translate) : ('ui.password.reset.button.label' | translate)"
                [icon]="inProgress ? 'pi pi-spin pi-spinner' : ''"
                [disabled]="inProgress"
                class="w-6 sm:w-8 col-offset-6 sm:col-offset-4 text-900 custom-p-button"></button>
      </div>
    </div>
  </form>
</div>
