import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';

import {Injector} from "@angular/core";
import {InterceptorService} from "./app/interceptors/interceptor.service";

platformBrowserDynamic().bootstrapModule(AppModule)
    .then((moduleRef) => {
        const injector: Injector = moduleRef.injector;

        const interceptorService = injector.get(InterceptorService);
        interceptorService.interceptCognito();
    })
    .catch(err => console.error(err));
