<div class="flex justify-content-center">
    <form [formGroup]="form" (ngSubmit)="form.valid && onChangePassword()"
          class="surface-card p-4 rcm-shadow border-round w-11 md:w-8 lg:w-8 xl:w-4">
        <div class="mb-5">
            <div class="text-700 text-xl font-normal mb-3">{{ 'ui.password.change.subject' | translate }}</div>
            <div class="text-700 font-normal line-height-3">{{ 'ui.password.change.description.1' | translate }}</div>
            <div class="text-700 font-italic line-height-3 mt-3 text-sm"><i
                    class="pi pi-info-circle mr-2 text-700"></i>{{ 'ui.password.pattern.info' | translate }}</div>
        </div>

        <div>
            <label for="newPassword"
                   class="block text-700 font-bold mb-2">{{ 'ui.password.change.new.password.new' | translate }}</label>
            <p-password id="newPassword" formControlName="newPassword" class="w-full mb-3"
                        [style]="{'width':'100%'}"
                        [inputStyle]="{'width':'100%'}"
                        [toggleMask]="true"
                        [feedback]="false"></p-password>
            <div *ngIf="form.get('newPassword')?.errors?.['required'] && form.get('newPassword')?.touched"
                 class="alert mb-4 mt-2">{{ 'ui.password.reset.new.password.description.1' | translate }}</div>
            <div *ngIf="form.get('newPassword')?.errors?.['pattern'] && form.get('newPassword')?.touched"
                 class="alert mb-4 mt-2">{{ 'ui.password.pattern.info' | translate }}</div>
        </div>

        <div class="mt-4">
            <label for="confirmPassword"
                   class="block text-700 font-bold mb-2">{{ 'ui.password.change.new.password.confirm' | translate }}</label>
            <p-password id="confirmPassword" formControlName="confirmPassword"
                        class="w-full mb-3"
                        [style]="{'width':'100%'}"
                        [inputStyle]="{'width':'100%'}"
                        [toggleMask]="true"
                        [feedback]="false"></p-password>
            <div *ngIf="form.get('confirmPassword')?.errors?.['required'] && form.get('confirmPassword')?.touched"
                 class="alert mb-4 mt-2">{{ 'ui.password.confirmation.required' | translate }}</div>
            <div *ngIf="form.get('confirmPassword')?.errors?.['pattern'] && form.get('confirmPassword')?.touched"
                 class="alert mb-4 mt-2">{{ 'ui.password.info.placeholder' | translate }}</div>
            <div *ngIf="form.errors?.['passwordMismatch'] && form.get('confirmPassword')?.touched"
                 class="alert mb-4">{{ 'ui.password.change.description.3' | translate }}</div>
        </div>

        <div class="text-700 font-normal line-height-3 mt-5"
             *ngIf="missingAttributes.length > 0">{{ 'ui.user.management.error.2' | translate }}</div>
        <div class="text-700 font-italic line-height-3 mt-3 text-sm"
             *ngIf="missingAttributes && missingAttributes.includes('phone_number')">
            <i class="pi pi-info-circle mr-2 text-700"></i>{{ 'ui.user.management.error.1' | translate }}
        </div>

        <div formArrayName="attributes" *ngFor="let attribute of attributes.controls; index as i">
            <div class="mt-4">
                <label [for]="'attribute' + i"
                       class="block text-700 font-bold mb-2">{{ missingAttributes[i] | translate }}</label>
                <input id="attribute{{i}}"
                       [placeholder]="missingAttributes[i] == 'phone_number' ? ('ui.phone.number.placeholder' |
                       translate) : ''"
                       type="text" [formControl]="getFormControl(attribute)"
                       pInputText class="w-full" [style]="{'width':'100%'}">
                <div *ngIf="attribute.errors?.['required'] && attribute.touched"
                     class="alert mb-4 mt-2">{{ missingAttributes[i] + 'ui.required' | translate }}</div>
                <div *ngIf="attribute.errors?.['pattern'] && attribute.touched"
                     class="alert mb-4 mt-2">{{ missingAttributes[i] + 'ui.pattern.info' | translate }}</div>
            </div>
        </div>

        <button pButton
                (click)="form.valid ? onChangePassword() : form.markAsTouched()"
                [label]="inProgress ? ('ui.please.wait' | translate) : ('ui.password.change.button.label' | translate)"
                [icon]="inProgress ? 'pi pi-spin pi-spinner' : ''"
                [disabled]="inProgress"
                class="w-6 col-offset-3 text-900 mt-4 custom-p-button"></button>
    </form>
</div>
